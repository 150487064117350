<template>
    <div class="login main_top ">
        <div class="sign_title"> Log In </div>
        <div class="dialog_content" >
            <div class="sign_container">
                <div class="dialog_content" v-if="signType=='login'" >
                            <el-form label-position="top" label-width="80px" :model="logForm" ref="ruleForm2" :rules="rules" >
                                <el-form-item label="" prop="email">
                                  <el-input v-model="logForm.email" placeholder="Please enter a valid email address"></el-input>
                                </el-form-item> 
                                <el-form-item label="" prop="code">
                                    <div class="code_item flex_sb">
                                        <div class="code_img" @click="refresh()"><img :src="codeImage" alt=""></div>
                                        <el-input v-model="logForm.code" placeholder="Please enter code"></el-input>
                                    </div>                      
                                </el-form-item> 
                                <el-form-item label="" prop="password">
                                  <el-input v-model="logForm.password" placeholder="Please enter password" type="password"></el-input>
                                </el-form-item> 
                            
                            </el-form>
                             <div class="dialog_btn" @click="login()">Log in</div>
                        </div>
                <div class="dialog_btn" @click="signup()">Log In </div>
            </div>
            
        </div>
    </div>
  </template>
  
  <script>

  import cookies from "../../utils/cookie";
  export default {
      components:{
        
      },
      data(){
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
            callback(new Error('Enter the full confrimPassword'));
            } else if (value !== this.signForm.confirmPassword) {
            callback(new Error('The password entered twice is inconsistent!'));
            } else {
            callback();
            }
        };
        var validateEmail = (rule, value, callback) => {
            let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (value === '') {
               callback(new Error('Please enter your e-mail'));
            } else if (!regex.test(value)) {
              callback(new Error('Invalid email'));
            } else {
              callback();
            }
        };
          return{
            logForm:{
                email:'',
                password:'',
                code:'',
                uuid:'',
            },
            rules:{
                email: [  { required: true, validator:validateEmail, trigger: 'blur' } ],
                password: [  { required: true, message: 'Enter the full password.', trigger: 'blur' } ],
                confirmPassword: [  { required: true, validator:validatePass2, trigger: 'blur' } ],
                code: [  { required: true, message: 'Please enter the code', trigger: 'blur' } ],
            },
            inviteUserCode:'',
            codeImage:'',
          }
      },
      methods:{
        toPage(path){
            this.$router.push(path)
        },
        getcaptchaImage(){
            this.$axiosApi.captchaImage().then(res=>{
                if(res.code == 200){
                    this.codeImage = res.img
                    this.signForm.uuid = res.uuid
                }
            })
        },
        refresh(){
            this.getcaptchaImage()
        },
        login(){
            let code = this.logForm.code
            let email = this.logForm.email
            let password = this.logForm.password
            let uuid = this.logForm.uuid
            let _this = this
            this.$refs['ruleForm2'].validate((valid) => {
                if (valid) {
                    this.$axiosApi.login({name:email,password,code,uuid}).then(res=>{         
                        if(res.code == 200){
                            this.$message.success(res.msg)
                            cookies.setToken(res.data.token)
                            setTimeout(()=>{
                                _this.$router.push('/index')
                                // _this.$store.commit('setShowDialog',false)
                                location.reload()
                            },1000) 
                        }else{
                            this.refresh()
                        }
                    })
                  
                } else {
                
                }
            })
            
        },
      },
      created(){
        let inviteUserCode = this.$route.query.inviteUserCode
        if(inviteUserCode){
            this.inviteUserCode = inviteUserCode
        }
        this.getcaptchaImage()
      }
  }
  </script>
  
  <style lang="scss" scoped>
   /deep/.el-input__inner{
        background:#eff2f5;
        height: 46px;
        border:1px solid #eff2f5;
        box-sizing: border-box;
        &:focus{
        border:1px solid #ffd633;
        }
    }
    .sign_title{
        margin-top:16px;
        padding:16px;
        color: #0f0f0f;
        text-align: left;
        font-weight: 600;
        background:#fff;
        margin-bottom:16px;
        border-radius: 4px;
    }
    .dialog_content{
        border-radius: 4px;
        background:#fff;
        padding:16px;
        .sign_container{
            max-width: 500px;
            margin:0 auto;
        }
    }
    .code_item{
        .code_img{
            width:50%;
            padding:0 10px;
            cursor: pointer;
        }
        .code_resect{
            padding:2px 4px;
            border-radius: 4px;
            font-size: 12px;
            color:#77808c;
            border:1px solid #d2d3d4;
            cursor: pointer;
        }
    }
    .dialog_btn{
        width: 100%;
        height: 48px;
        font-size: 16px;
        line-height: 48px;
        margin-top: 40px;
        font-weight: 700;
        cursor: pointer;
        background:#ffcb2e;
        border-radius: 6px;
        text-align: center;
        &:hover{
            opacity: 0.7;
        }
    }
  
  </style>